import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Client } from './components/Client';
import Loading from './components/Loading';

import constants from './constants/constants';

import 'styles/Site.scss';

Sentry.init({
    dsn: "https://0e679dd594d6a2b2f15fe658780a2036@o4507028880883712.ingest.us.sentry.io/4507051827789824",
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'undefined',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/pubquizraceweb-stage\.azurewebsites\.net/, /^https:\/\/pubquiz\.game\.city/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            percentage: 0,
        };
    }

    componentDidMount() {
        let percentage = this.state.percentage;
        let percentPerItem = 100 / (constants.fonts.length + constants.images.length);
        // Preload images
        setTimeout(() => {
            Promise.all(constants.images.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image;
                }).then(() => {
                    percentage += percentPerItem;
                    this.setState({ percentage });
                });
            })).then(() => {
                // Preload fonts
                return Promise.all(constants.fonts.map(font => {
                    return new Promise((resolve, reject) => {
                        const fontFace = new FontFace(font.font, `url(${font.path})`);
                        fontFace.load().then(() => {
                            document.fonts.add(fontFace);
                            percentage += percentPerItem;
                            this.setState({ percentage });
                            resolve();
                        }).catch(reject);
                    });
                }));
            }).then(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 3000);
                console.log('Assets preloaded!');
            }).catch(error => {
                // if there's an error, we still want to show the app, so set isLoading to false
                this.setState({ isLoading: false });
                console.error('Error preloading resources:', error);
            });
        }, 1500);
    }

    render() {
        return (
            <Layout>
                {
                    this.state.isLoading ?
                        <Loading loadingText={""} progress={this.state.percentage} />
                        :
                        <>
                            <Route exact path='/' component={Home} />
                            <Route path='/client' component={Client} />
                        </>
                }
            </Layout>
        );
    }
}