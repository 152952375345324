import React, { Component } from 'react';
import { Route } from 'react-router';
import LoggingService from "services/logging";

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import DefaultView from "components/DefaultView";
import BonusActions from "components/BonusActions";

import styles from 'components/ClientStyles.module.scss';

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    // your game states here (identical to server)
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
    Categories: "categories",
    Question: "question",
    Answers: "answers",
    GetReady: "get_ready",
    Race: "race",
    RaceQuestion: "race_question",
    Results: "results",
};

const gameId = "pub_quiz";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            gameState: GameStates.Playing,
            reconnectionToken: "",
            logStreamId: "",

            categories: [],
            question: null,
            selectedCategory: null,
            showCategoryView: false,
            categorySelectorId: "",
            roundNumber: 0,
            questionNumber: 0,

            player: null,
            doingTutorial: false,

            showQuestion: false,

            showRaceQuestion: false,
            raceQuestion: null,
            raceAnswerIndexes: [],
            players: [],

            submitted: false,

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
    doCatchup(gameState = null) {
        console.log("Catchup with gameState : ", this.state.gameState);
        switch (gameState || this.state.gameState) {
            case GameStates.Tutorial:
                if (!this.state.player.votedSkip) {
                    this.setState({ doingTutorial: true });
                }
                break;
            case GameStates.Categories:
                this.setState({ showCategoryView: true, })
                break;
            case GameStates.Question:
                if (!this.state.player.pubData.submitted) {
                    if (this.state.question == null || this.state.question === "") {
                        console.log("No question found.. request it");
                        this.state.room.send("get_pub_question", {});
                    } else if (!this.state.showQuestion) {
                        console.log("Showing question");
                        this.setState({ showQuestion: true });
                    }
                } else {
                    this.setState({ showQuestion: false });
                }
                break;
            case GameStates.RaceQuestion:
                if (this.state.raceQuestion == null) {
                    this.state.room.send("get_race_question", {});
                } else if (this.state.showRaceQuestion == null) {
                    this.setState({ showRaceQuestion: true });
                }
                break;
            default:
                this.setState({ question: null, raceQuestion: null, showCategoryView: false, showQuestion: false, showRaceQuestion: false, });
                break;
        }
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    selectCategory = () => {
        this.state.room.send("select_category");
    }

    updateAnswer(roundNumber, answer) {
        this.state.room.send("update_answer", { roundNumber, answer });
    }

    submitAnswer() {
        if (this.state.player.pubData.answer == null) return;
        if (this.state.player.pubData.submitted) return;
        if (!this.state.question.answers.find((x) => x.answer == this.state.player.pubData.answer)) return;
         
        this.state.room.send("submit_answer", { submitted: true });
        this.setState({ submitted: true, showQuestion: false });
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    clickRaceAnswer(index) {
        this.state.room.send("click_race_answer", { answerIndex: index });
    }

    shouldShowDefaultView() {

        return !this.state.showCategoryView && !this.state.showQuestion && !this.state.showRaceQuestion && !this.state.doingTutorial;
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    shouldRemoveAnswer(answer) {
        if (this.state.player.pubData.usedRemoveOneAnswer.length > 0) {
            if (this.state.player.pubData.usedRemoveOneAnswer[0] == this.state.roundNumber && this.state.player.pubData.usedRemoveOneAnswer[1] == this.state.questionNumber) {
                if (this.state.player.pubData.removedAnswer === answer) {
                    return true;
                }
            }
        }
        return false;
    }

    updateToken(token) {
        var url = new URL(window.location.href);
 
        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        const roomId = this.getQueryStringValue("roomId");
        const sessionId = this.getQueryStringValue("sessionId");
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.startLocationChecks();
                LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Pub Quiz, Reconnection Token: ${room.reconnectionToken}`);

                this.setState({ player, isPaused: state.isPaused, roomState: state, logStreamId: state.uniqueId, });
                this.doCatchup();

            });
            room.onStateChange((state) => {
                console.log("room has new state:", state);
                this.setState({ roomState: state, });
                this.doCatchup();
            });

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.state.players.onAdd((player, key) => {
                this.checkAndAddPlayer(player);

                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });

                if (player.id === room.sessionId) {
                    console.log(player, "has been added at", key);

                    player.onChange(() => {
                    });
                    player.pubData.onChange(() => {
                        this.doCatchup()
                    });
                    player.pubData.raceAnswers.onChange((change, index) => {
                        console.log("change ", change, "index", index);
                        let statePlayer = { ...this.state.player };
                        if (statePlayer) {
                            statePlayer.pubData.raceAnswers[index] = change;
                            this.setState({ player: statePlayer });
                        }
                    });
                    player.pubData.listen("submitted", (currentValue, previousValue) => {
                        console.log(`submitted change detected : ${currentValue}`);
                        let statePlayer = { ...this.state.player };
                        if (statePlayer) {
                            statePlayer.pubData.submitted = currentValue;
                            this.setState({ player: statePlayer });
                        }
                    });

                    // Note: below is not needed as it is a string, detecting changes of maps and arrays is allowed
                    //       the below will be handled by in the pubData.onChange event
                    //player.pubData.answer.onChange = (change, index) => {
                    //    console.log("change ", change, "index", index);
                    //    let statePlayer = { ...this.state.player };
                    //    statePlayer.pubData.answer = change;
                    //    this.setState({ player: statePlayer });
                    //};
                }
            });
            


            room.state.pubData.listen("gameState", (currentValue, previousValue) => {
                console.log(`gameState change detected : ${currentValue}`);
                this.setState({ gameState: currentValue });
                this.doCatchup(currentValue);
            });
            room.state.pubData.listen("question", (currentValue, previousValue) => {
                console.log(`Question change detected : ${currentValue}`);
                this.setState({ question: JSON.parse(currentValue) })
            });
            room.state.pubData.listen("roundNumber", (currentValue, previousValue) => {
                console.log(`roundNumber change detected : ${currentValue}`);
                this.setState({ roundNumber: currentValue })
            });
            room.state.pubData.listen("questionNumber", (currentValue, previousValue) => {
                console.log(`questionNumber change detected : ${currentValue}`);
                this.setState({ questionNumber: currentValue })
            });
            room.state.pubData.listen("categorySelectorId", (currentValue, previousValue) => {
                console.log(`categorySelectorId change detected : ${currentValue}`);
                this.setState({ categorySelectorId: currentValue });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false });
            });

            room.onMessage("start_playing", (message) => {
                console.log("start_playing", "received on", room.name);
            });

            room.onMessage("new_round", (message) => {
                console.log("new_round", "received on", room.name, message);
                console.log("category_selector_id: " + message.categorySelector.id);
                console.log("my_id: " + this.state.myId);
                this.setState({ categorySelector: message.categorySelector, categorySelectorId: message.categorySelector.id, });
            });

            room.onMessage("question", (message) => {
                console.log("question", "received on", room.name, message);
                this.setState({ showQuestion: true, question: message.question });
            });

            room.onMessage("race_question", (message) => {
                console.log("race_question", "received on", room.name, message);
                this.setState({ raceQuestion: message.question, raceAnswerIndexes: message.answerIndexes, showRaceQuestion: true });
            });

            room.onMessage("race_validation", (message) => {
                console.log("race_validation", "received on", room.name, message);
            });

            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });

            // your game message handlers here

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Pub Quiz, code: ${code} Message: ${JSON.stringify(message)}`);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Pub Quiz, Code: ${code}`);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Pub Quiz: ${JSON.stringify(e)}`);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.state.connectionIssue &&
                                <div className={styles.connectionIssueContainer}>
                                    <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                    <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                </div>
                            }
                            {
                                this.shouldShowDefaultView() &&
                                <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
                            }

                            {
                                this.state.showCategoryView &&
                                <>
                                    <div className={styles.categorySelector}>
                                        {
                                            this.state.categorySelectorId == this.state.myId && !this.state.player?.pubData.hasSelectedCategory ?
                                                <>
                                                    <p>{this.state.player?.name}, press below to select a random category!</p>
                                                    <button onClick={() => this.selectCategory()}>Press me</button>
                                                </>
                                                :
                                                <>
                                                    {
                                                        this.state.roomState != null && this.state.roomState.players != null && this.state.roomState.players[this.state.categorySelectorId] != null &&
                                                        <p>{this.state.roomState.players[this.state.categorySelectorId].name} is choosing a category...</p>
                                                    }
                                                </>
                                        }
                                    </div>
                                </>
                            }
                            {
                                this.state.showQuestion &&
                                <>
                                    <div className={styles.questionSection}>
                                        {
                                            <div className={styles.questionAnswerContainer}>
                                                <div className={styles.questionNumber}><p>{this.state.questionNumber}</p></div>
                                                <div className={styles.question}>{this.state.question?.question}</div>
                                                {
                                                    this.state.question?.answers?.map((a, i) => {
                                                        if (this.shouldRemoveAnswer(a.answer)) return null;
                                                        return <div key={i} className={`${styles.answerOption} ${this.state.player?.pubData.answer == a.answer && styles.selected}`} onClick={() => this.updateAnswer(this.state.roundNumber, a.answer)}>
                                                            <p className={styles.questionText}>{a.answer.toString()}</p>
                                                        </div>;
                                                    })
                                                }
                                                <button className={styles.alt} onClick={() => this.submitAnswer()}>Submit</button>
                                            </div>
                                        }
                                    </div>
                                    <BonusActions player={this.state.player} players={this.state.players} room={this.state.room} />
                                </>
                            }
                            {/*{*/}
                            {/*    this.state.gameState === GameStates.Results &&*/}
                            {/*    <>*/}
                            {/*        <div className={styles.questionSection}>*/}
                            {/*            {*/}
                            {/*                <div className={styles.questionAnswerContainer}>*/}
                            {/*                    <div className={styles.questionNumber}><p>{this.state.questionNumber}</p></div>*/}
                            {/*                    <div className={styles.question}>Were you correct?</div>*/}
                            {/*                    {*/}
                            {/*                        this.state.question.answers.map((a, i) => {*/}
                            {/*                            return <div key={i} className={`${styles.answerOption} ${a.isCorrect && this.player.answer != a.answer ? styles.showCorrectAnswer : ""}  ${a.isCorrect && this.player.answer == a.answer ? styles.isCorrectAnswer : ""}  ${!a.isCorrect && this.player.answer == a.answer ? styles.isIncorrectAnswer : ""}`}>*/}
                            {/*                                <p className={styles.questionText}>{a.answer.toString()}</p>*/}
                            {/*                            </div>;*/}
                            {/*                        })*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        </div>*/}
                            {/*    </>*/}
                            {/*}*/}
                            {
                                this.state.showRaceQuestion &&
                                <>
                                    <div className={styles.questionSection}>
                                        <h3>Don't get one wrong!</h3>
                                        <div className={styles.question}>{this.state.raceQuestion.question}</div>
                                        {/*<div className={styles.raceAnswers}>*/}
                                        {
                                            this.state.raceQuestion.answers.map((a, i) => {
                                                if (this.state.raceAnswerIndexes.includes(i)) {
                                                    return <div key={i} className={`${styles.answerOption} ${this.state.player?.pubData.raceAnswers[this.state.raceAnswerIndexes.indexOf(i)] && styles.selected}`} onClick={() => this.clickRaceAnswer(this.state.raceAnswerIndexes.indexOf(i))}>
                                                        <p className={styles.questionText}>{a.answer.toString()}</p>
                                                    </div>
                                                }
                                            })
                                        }
                                        {/*</div>*/}
                                    </div>
                                </>
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
