import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
//import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/Player";
import Timer from "components/Timer";
import Tutorial from "components/Tutorial";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/HostStyles.module.scss';

import logo from "images/logo.png";
import tablesLeft from "images/tables_left.png";
import tablesRight from "images/tables_right.png";
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import barImg from "images/bar-v1.png";
import Lottie from 'react-lottie';

import Table from "images/temp-table.png";
import StoolImg from "images/stool.png";
import PintImg from "images/pint.png";
import WineImg from "images/wine.png";
import BottleImg from "images/bottle.png";
import BeerBottleImg from "images/beer-bottle.png";
import GlassImg from "images/glass.png";
import SteinImg from "images/stein.png";

import BGMusic from "audio/bgMusic.mp3";
import PointSFX from "audio/point.wav";
import WooshSFX from "audio/woosh.wav";
import Timer5SecondsSFX from "audio/timer-5-seconds-clock.mp3";
import BellSFX from "audio/bellDing.mp3";
import CategorySelectSFX from "audio/categorySelected.wav";
import PopupSFX from "audio/popup.wav";
import Woosh2SFX from "audio/woosh-2.wav";
import CategoryScrollSFX from "audio/categoryScroll.wav";
import PlayerActionSFX from "audio/playerAction.wav";
import WinnerAnnouncedSFX from "audio/winner-announced.wav";
import NoWinnerSFX from "audio/no-winners.wav";
import ErrorModal from './Utility/ErrorModal';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    PointSound: {
        import: PointSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    WooshSound: {
        import: WooshSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Woosh2Sound: {
        import: Woosh2SFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5Seconds: {
        import: Timer5SecondsSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Bell: {
        import: BellSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    CategorySelect: {
        import: CategorySelectSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Popup: {
        import: PopupSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    CategoryScroll: {
        import: CategoryScrollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PlayerAction: {
        import: PlayerActionSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    WinnerAnnounced: {
        import: WinnerAnnouncedSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NoWinner: {
        import: NoWinnerSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
    Categories: "categories",
    Question: "question",
    Answers: "answers",
    GetReady: "get_ready",
    Race: "race",
    RaceQuestion: "race_question",
    Results: "results",
};

const gameId = "pub_quiz";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            reconnectionToken: "",
            logStreamId: "",

            categories: [],
            selectedCategories: [],
            selectedCategory: null,
            categorySelector: null,
            questionsPerRound: 0,
            questionNumber: 0,
            pauseTimer: false,
            question: {
                question: "This is a question?",
                answers: []
            },
            //question: null,
            roundNumber: 0,
            rounds: null,

            players: [],
            //players: [
            //    {
            //        id: 1, name: "Player 1", avatar: 1, score: 0, connected: true, showPlayer: true, pubData: {raceProgress: 20},
            //    },
            //    {
            //        id: 2, name: "Player 2", avatar: 2, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 3, name: "Player 3", avatar: 3, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 4, name: "Player 4", avatar: 4, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 5, name: "Player 5", avatar: 5, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 6, name: "Player 6", avatar: 6, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 7, name: "Player 7", avatar: 7, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //    {
            //        id: 8, name: "Player 8", avatar: 8, score: 0, connected: true, showPlayer: true, pubData: { raceProgress: 20 },
            //    },
            //],
            playersRequired: 2,
            showPlayers: true,
            //showPlayers: false,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,
            skipTutorialCount: 0,
            showStartButtons: true,
            showPubSection: false,

            isSelectingCategory: false,
            showCategorySection: false,
            showCategories: false,

            showGetReady: false,
            showGetReadyBox: false,

            showQuestion: false,
            showQuestionText: false,
            showAnswerOptions: false,
            showPlayerAnswers: false,
            showCorrectAnswer: false,

            doConfetti: false,
            showRaceIntro: false,
            showRaceStartPopup: false,
            showRacePopupText: false,
            showRacePopupPlayers: false,

            showRaceQuestion: false,
            showRaceQuestionText: false,
            showRaceAnswerOptions: false,
            isRace: false,
            //isRace: true,
            raceQuestion: {},
            raceAnswerIndexes: [],
            showRaceQuestion: false,
            showRaceCorrect: false,
            showRacePlayersValidation: false,

            raceStartPopupStep: 0,
            topThreePlayerLists: {},

            showRoundsHeader: false,
            showQuestionsHeader: false,

            showGameOver: false,
            showRaceHeader: false,
            raceQuestionsLeft: 0,
            showGameOverPanel: false,
            showPlayAgainButtons: false,

            winnerName: null,
            winners: [],

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();

        this.doReconnect();

        //this.toggleMute(true, false);
        this.initAudio();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audioObj) {
        if (audioObj.loaded) audioObj.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-move-${id}`)) {
            this.animateCSS(`#potato-move-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                player.moveIndex = 0;
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    setConnected(id, connected) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connected = connected;

        players[pos] = player;

        this.setState({ players });
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    addPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartButtons: false, showStartWarning: false, });
            setTimeout(() => {
                //this.state.room.send("begin_game", { skipTutorial: true, });
                this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
            }, 500);
        }
    }

    goToLobby = () => {
        this.state.room.send("change_game", {});
    }

    signalNewGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showPlayers: true, showGameOver: false, showGameOverPanel: false, showStartWarning: false, });
            this.resetGameVals();
            setTimeout(() => {
                this.state.room.send("pubquiz_new_game", {});
            }, 500);
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.signalNewGame(true);
    }

    confirmStartGame = () => {
        this.signalStartGame(true);
    }

    resetGameVals() {
        let statePlayers = [...this.state.players];
        statePlayers.forEach((p, i) => {
            let statePlayer = { ...p };
            statePlayer.moveIndex = 0;
            statePlayers[i] = statePlayer;
        });
        this.setState({ players: statePlayers, });
    }

    signalRoundOver = (roundNumber) => {
        this.state.room.send("round_over", { roundNumber });
    }

    signalNewRound = () => {
        this.state.room.send("round_start");
    }

    signalNextQuestion = () => {
        this.state.room.send("next_question");
    }

    updateTimer(count) {
        if (count == 6 && (this.state.showQuestion || this.state.showRaceQuestion)) {
            this.playAudio(audio.Timer5Seconds);
        }
        this.setState({ timer: count, showTimer: true, });
    }

    startCategorySelection = () => {
        this.setState({ showCategorySection: true, });
        setTimeout(() => {
            this.setState({ showCategories: true, isSelectingCategory: true, });
            this.playAudio(audio.Popup);

            setTimeout(() => {
                let nextCategory = null;
                let nextCategoryIndex = 0;
                let currentCategory = null;
                let initial = 750;
                let currentSpeed = initial;
                let minSpeed = 200;
                let interval = 50;

                var highlight = () => {
                    if (!this.state.isSelectingCategory) return;

                    // get the next category in order
                    currentCategory = this.state.selectedCategory == null ? this.state.categories[0] : this.state.selectedCategory;
                    nextCategoryIndex = this.state.categories.indexOf(currentCategory) + 1;
                    nextCategory = this.state.categories[nextCategoryIndex];
                    // if we're at the end of the list, start again
                    if (!nextCategory) nextCategory = this.state.categories[0];

                    // update the category to selected
                    this.setState({ selectedCategory: nextCategory });
                    this.playAudio(audio.CategoryScroll);

                    currentSpeed = (currentSpeed - interval) >= 200 ? (currentSpeed - interval) : minSpeed;

                    if (this.state.isSelectingCategory) {
                        console.log('Selecting category!');
                        setTimeout(highlight, currentSpeed);
                    }
                }

                highlight();
                this.state.room.send("start_categories", {});
            }, 2000);
        }, 100);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    getRaceSegments(isLeft) {
        let segments = [];

        for (let i = 0; i < this.state.roomState?.pubData.raceSteps; i++) {
            segments.push(<div id={`${isLeft ? "left" : "right"}-segment-${i + 1}`} className={styles.segment}>
                <div className={styles.segmentDot}></div>
            </div>);
        }

        return segments;
    }

    getRacePopupText() {
        let text = "";
        if (this.state.raceStartPopupStep === 0) {
            text = "So, who will be starting the Race to the Bar in pole position?";
        } else if (this.state.raceStartPopupStep === 1) {
            text = "Who's following close behind in second?";
        } else if (this.state.raceStartPopupStep === 2) {
            text = "And who's getting the final advantage in third?";
        }
        return text;
    }

    getRacePopupPlayerList() {
        let arrToUse;
        if (this.state.raceStartPopupStep === 0) {
            arrToUse = this.state.topThreePlayerLists.playersInFirst;
        } else if (this.state.raceStartPopupStep === 1) {
            arrToUse = this.state.topThreePlayerLists.playersInSecond;
        } else if (this.state.raceStartPopupStep === 2) {
            arrToUse = this.state.topThreePlayerLists.playersInThird;
        }

        let playerList = [];
        for (let i = 0; i < arrToUse.length; i++) {
            playerList.push(<div className={styles.racePopupPlayer}>
                <div className={styles.avatar}>
                    <Lottie
                        options={getAvatarById(arrToUse[i].avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <p className={styles.playerName}>{arrToUse[i].name}</p>
            </div>);
        }
        return playerList;
    }

    getWinners() {
        let winners = [];
        let count = 0;
        let drinks = [PintImg, WineImg, BottleImg, BeerBottleImg, GlassImg, SteinImg];
        this.state.players.forEach((p, i) => {
            if (p.pubData.raceProgress == (this.state.roomState ? this.state.roomState.pubData.raceSteps : 20)) {
                //this.setState({ winnerName: p.name });
                count++;
                let drink = drinks[i % drinks.length];
                return <div className={`${styles.winner} ${styles["winner-" + (count)]}`}>
                    <div className={styles.avatar}>
                        <Lottie
                            options={getAvatarById(p.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                        <img className={styles.stool} src={StoolImg} />
                    </div>
                    {/*<img className={styles.drink} src={drink} />*/}
                    {/*<p className={styles.playerName}>{p.name}</p>*/}
                </div>
            } else {
                return <div>No winners</div>
            }
        });
    }

    showQuestion() {
        this.setState({ showQuestion: true, showQuestionsHeader: true, });

        setTimeout(() => {
            this.setState({ showQuestionText: true, });
            this.playAudio(audio.Popup);

            setTimeout(() => {
                this.setState({ showAnswerOptions: true, });
                this.playAudio(audio.Popup);

                setTimeout(() => {
                    this.setState({ pauseTimer: false, });
                    this.state.room.send("show_question");

                }, 500);
            }, 2500);
        }, 1000);
    }

    getQuestionFontSizeStyle() {
        if (this.state.question && this.state.question.question) {
            let question = this.state.question.question;
            if (question.length >= 100) {
                return styles.smallest;
            } else if (question.length >= 75) {
                return styles.small;
            } else {
                return styles.normal;
            }
        } else {
            return null;
        }
    }

    getWinnerFontSize() {
        // large, medium, small, xsmall
        if (this.state.winnerName) {
            let name = this.state.winnerName;
            if (name.length >= 30) {
                return styles.xsmall;
            } else if (name.length >= 20) {
                return styles.small;
            } else if (name.length > 10) {
                return styles.medium;
            } else {
                return styles.large;
            }
        } else {
            return null;
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Pub Quiz");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const roomId = this.getQueryStringValue("roomId");
            const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            console.log(roomId);

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });


                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Pub Quiz, Reconnection Token: ${room.reconnectionToken}`);

                        this.setState({ roomState: state, logStreamId: state.uniqueId, });

                        // this casues everyone to go back to lobby if the host has refreshed their page mid game
                        if (state.pubData.gameState === GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.onMessage("show_players", (message) => {
                        console.log("show_players", "received on", room.name, message);
                        for (const [key, value] of Object.entries(message.players)) {
                            this.addPlayer(value);
                        }
                    });

                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartGame: false, })
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);

                        player.listen("connected", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.connected = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        player.listen("votedSkip", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.votedSkip = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        const changes = ["score", "answer", "submitted", "isCorrect", "hasSelectedCategory", "raceProgress", "playerIdToCopy", "removedAnswer"];
                        changes.forEach((change) => {
                            player.pubData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };
                                statePlayer.pubData[change] = value;
                                if (change === "score" && value > 0) this.playAudio(audio.PointSound);
                                if (change === "submitted" && value === true) this.playAudio(audio.PlayerAction);
                                
                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });
                        player.pubData.raceAnswers.onChange((change, index) => {
                            console.log("change ", change, "index", index);
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };
                            statePlayer.pubData.raceAnswers[index] = change;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                    });

                    room.state.pubData.onChange(() => {
                    });
                    room.state.pubData.listen("gameState", (value) => {
                        if (value != GameStates.Loading && value != GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ gameState: value });
                    });
                    room.state.pubData.listen("roundNumber", (value) => {
                        this.setState({ roundNumber: value });
                    });
                    room.state.pubData.listen("questionNumber", (value) => {
                        this.setState({ questionNumber: value });
                    });
                    room.state.pubData.listen("currentQuestionId", (value) => {
                        this.setState({ currentQuestionId: value });
                    });
                    room.state.pubData.listen("selectedCategory", (value) => {
                        this.setState({ selectedCategories: [...this.state.selectedCategories, value] })
                    });
                    room.state.pubData.listen("question", (value) => {
                        this.setState({ question: JSON.parse(value) });
                    });
                    room.state.pubData.listen("raceQuestionsLeft", (value) => {
                        this.setState({ raceQuestionsLeft: value });
                    });


                    room.state.players.onRemove = (player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    };

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, });
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.pubData.gameState);
                        if ((this.state.roomState.pubData.gameState === GameStates.Loading || this.state.roomState.pubData.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, showPubSection: true, });
                            setTimeout(() => {
                                room.send("start_playing", {});
                            }, 1500);
                        }
                    });

                    room.onMessage("player_leave", (message) => {
                        console.log("player_leave", "received on", room.name, message);
                        this.removePlayer(message.sessionId);
                    });
                    room.onMessage("player_joined", (message) => {
                        console.log("player_joined", "received on", room.name, message);
                        this.addPlayer(message.player);
                    });

                    room.onMessage("set_player_connected", (message) => {
                        console.log("set_player_connected", "received on", room.name, message);
                        this.setConnected(message.id, message.connected);
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);
                        this.updateTimer(message.count);
                    });

                    room.onMessage("start_playing", (message) => {
                        console.log("start_playing", "received on", room.name, message);
                        this.setState({ categories: message.categories, rounds: message.rounds, questionsPerRound: message.questionsPerRound, isSelectingCategory: true, });
                        this.state.room.send("round_start");
                    });

                    room.onMessage("new_round", (message) => {
                        console.log("new_round", "received on", room.name, message);
                        this.setState({ categorySelector: message.categorySelector, showPlayers: true, showRoundsHeader: true, showQuestionsHeader: false, });
                        this.startCategorySelection();
                    });

                    room.onMessage("round_over", (message) => {
                        console.log("round_over", "received on", room.name, message);
                        this.state.room.send("round_start");
                    });

                    room.onMessage("round_results", (message) => {
                        console.log("round_results", "received on", room.name, message);
                        setTimeout(() => {
                            if (this.state.roundNumber <= this.state.rounds.length) {
                                this.signalNewRound();
                            }
                        }, 5000);
                    });

                    room.onMessage("select_category", (message) => {
                        console.log("select_category", "received on", room.name, message);

                        this.setState({ isSelectingCategory: false, showGetReady: true, });
                        this.playAudio(audio.CategorySelect);

                        setTimeout(() => {
                            this.setState({ showGetReadyBox: true, });
                            this.playAudio(audio.Popup);

                            setTimeout(() => {
                                this.setState({ showCategories: false, });

                                setTimeout(() => {
                                    this.state.room.send("category_selected", { selectedCategory: this.state.selectedCategory, });
                                }, 500);
                            }, 1000);
                        }, 2000);
                    });

                    room.onMessage("get_ready", (message) => {
                        console.log("get_ready", "received on", room.name, message);
                        this.setState({ showCategorySection: false, });
                    });

                    room.onMessage("get_ready_ended", (message) => {
                        console.log("get_ready_ended", "received on", room.name, message);
                        this.setState({ showGetReadyBox: false, });

                        setTimeout(() => {
                            this.setState({ showGetReady: false, });

                            setTimeout(() => {
                                this.showQuestion();
                            }, 500);
                        }, 1000);
                    });

                    room.onMessage("question_ended", (message) => {
                        console.log("question_ended", "received on", room.name, message);
                        this.setState({ pauseTimer: true, });

                        setTimeout(() => {
                            this.setState({ showPlayerAnswers: true, });
                            this.playAudio(audio.Popup);

                            setTimeout(() => {
                                this.setState({ showCorrectAnswer: true });
                                this.playAudio(audio.Bell);

                                setTimeout(() => {
                                    this.state.room.send("award_scores", {});

                                    setTimeout(() => {
                                        this.setState({ showQuestionText: false, showAnswerOptions: false, });
                                        this.playAudio(audio.WooshSound);

                                        setTimeout(() => {
                                            this.setState({ showQuestion: false, showPlayerAnswers: false, showCorrectAnswer: false });
                                            this.state.room.send("show_results");
                                        }, 500);
                                    }, 1500);
                                }, 1000);
                            }, 3000);
                        }, 3000);

                    });

                    room.onMessage("results_ended", (message) => {
                        console.log("results_ended", "received on", room.name, message);
                        this.showQuestion();
                    });

                    room.onMessage("start_race", (message) => {
                        console.log("start_race", "received on", room.name, message);
                        let topThreePlayerLists = message.topThree;
                        this.setState({ showRaceIntro: true, isRace: true, topThreePlayerLists, showQuestionsHeader: false, raceStartPopupStep : 0, showRacePopupText: false, showRacePopupPlayers: false, });
                        let timeout1 = 0;
                        let doStep1 = false;
                        let timeout2 = 0;
                        let doStep2 = false;

                        if (topThreePlayerLists.playersInSecond.length > 0) {
                            timeout1 = 6000;
                            doStep1 = true;
                        }
                        if (topThreePlayerLists.playersInThird.length > 0) {
                            timeout2 = 6000;
                            doStep2 = true;
                        }

                        setTimeout(() => {
                            this.setState({ showRaceStartPopup: true, });
                            this.playAudio(audio.Popup);

                            setTimeout(() => {
                                this.setState({ showRacePopupText: true, showRacePopupPlayers: true, });
                                this.playAudio(audio.Woosh2Sound);

                                setTimeout(() => {
                                    if (doStep1) {
                                        this.setState({ showRacePopupText: false, showRacePopupPlayers: false, });
                                        setTimeout(() => {
                                            this.setState({ raceStartPopupStep: 1, });
                                            this.setState({ showRacePopupText: true, showRacePopupPlayers: true, });
                                            this.playAudio(audio.Woosh2Sound);
                                        }, 1500);
                                    }

                                    setTimeout(() => {
                                        if (doStep2) {
                                            this.setState({ showRacePopupText: false, showRacePopupPlayers: false, });
                                            setTimeout(() => {
                                                this.setState({ raceStartPopupStep: 2, });
                                                this.setState({ showRacePopupText: true, showRacePopupPlayers: true, });
                                                this.playAudio(audio.Woosh2Sound);
                                            }, 1500);
                                        }

                                        setTimeout(() => {
                                            this.setState({ showRaceStartPopup: false, showRaceQuestion: true, });

                                            setTimeout(() => {
                                                let statePlayers = [...this.state.players];
                                                statePlayers.forEach((p, i) => {
                                                    let statePlayer = { ...p };
                                                    statePlayer.moveAmount = p.pubData.raceProgress - statePlayer.moveIndex;
                                                    statePlayer.moveIndex = p.pubData.raceProgress;
                                                    statePlayers[i] = statePlayer;
                                                });
                                                this.setState({ players: statePlayers, showRaceHeader: true, showRaceIntro: false, });
                                                this.playAudio(audio.WooshSound);

                                                setTimeout(() => {
                                                    this.state.room.send("begin_race", {});
                                                }, 2000);
                                            }, 1000);
                                        }, timeout2);
                                    }, timeout1);
                                }, 3000);
                            }, 750);
                        }, 250);
                    });

                    room.onMessage("race_question", (message) => {
                        console.log("race_question", "received on", room.name, message);
                        this.playAudio(audio.Popup);
                        this.setState({ showRaceQuestionText: true, showRaceAnswerOptions: true, raceQuestion: message.question, raceAnswerIndexes: message.answerIndexes, });
                    });

                    room.onMessage("race_validation", (message) => {
                        console.log("race_validation", "received on", room.name, message);
                        this.setState({ showRacePlayersValidation: true, });
                        this.playAudio(audio.Popup);

                        setTimeout(() => {
                            this.setState({ showRaceCorrect: true, });
                            this.playAudio(audio.Bell);

                            setTimeout(() => {
                                this.setState({ showRaceQuestionText: false, showRaceAnswerOptions: false, });

                                setTimeout(() => {
                                    let statePlayers = [...this.state.players];
                                    let playersMoved = false;

                                    statePlayers.forEach((p, i) => {
                                        let statePlayer = { ...p };
                                        if (p.pubData.raceProgress != statePlayer.moveIndex) playersMoved = true;
                                        statePlayer.moveAmount = p.pubData.raceProgress - statePlayer.moveIndex;
                                        statePlayer.moveIndex = p.pubData.raceProgress;
                                        statePlayers[i] = statePlayer;
                                    });
                                    this.setState({ players: statePlayers, });
                                    this.playAudio(audio.WooshSound);

                                    setTimeout(() => {
                                        this.setState({ showRacePlayersValidation: false, showRaceCorrect: false, });

                                        setTimeout(() => {
                                            this.state.room.send("next_race_question", {});
                                        }, 750);
                                    }, 1000);
                                }, 1000);
                            }, 5000);
                        }, 2000);
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        if (message.winners != null) {
                            let winnerName = "";
                            message.winners.forEach((w, i) => {
                                winnerName += w.name;
                                if (i < message.winners.length - 1) winnerName += ", ";
                            });
                            this.setState({ winnerName, winners: message.winners });
                        }
                        setTimeout(() => {
                            this.setState({ showGameOver: true, showRaceQuestion: false, showRaceHeader: false, });

                            setTimeout(() => {
                                if (message.winners == null) {
                                    this.setState({ showRaceQuestion: false, showRaceHeader: false, showGameOverPanel: true, doConfetti: false, });
                                    this.playAudio(audio.NoWinner);
                                } else {
                                    this.setState({ showRaceQuestion: false, showRaceHeader: false, showGameOverPanel: true, doConfetti: true, });
                                    this.playAudio(audio.WinnerAnnounced);
                                }

                                setTimeout(() => {
                                    this.state.room.send("reached_end", {});
                                    this.setState({ showPlayAgainButtons: true });

                                    setTimeout(() => {
                                        this.setState({ isRace: false, showPlayers: false, });
                                    }, 1000);
                                }, 2000);
                            }, 500);
                        }, 2000);
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}/lobby/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);

                        this.setState({ showPlayers: true, showGameOver: false, showGameOverPanel: false, });
                        this.resetGameVals();

                        room.send("start_playing", {});
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Pub Quiz, code: ${code} Message: ${JSON.stringify(message)}`);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Pub Quiz, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Pub Quiz.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Pub Quiz: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}/` });
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={`${styles.startContainer} ${styles.show}`}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />

                <div id="gameContainer" className={`${styles.gameContainer}`}>

                    {
                        !this.state.connected ?
                            <div className={`${styles.innerContainer} ${styles.start}`}>
                                <div className={styles.logoSection}>
                                    <img src={logo} className={styles.logo} />
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>game.city</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} />
                                </div>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartButtons ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                <div className={`${styles.innerContainer} ${styles.start} ${this.state.showPubSection == true && styles.hide}`}>
                                    {
                                        this.state.showStartButtons &&
                                        <div className={styles.startButtonSection}>
                                            <div className={styles.centerLogo}>
                                                <img className={styles.logoImg} src={logo} />
                                            </div>
                                            <div className={styles.buttonsSection}>
                                                <button className={`${styles.button}`} onClick={() => this.signalStartGame()}>
                                                    Start Game
                                                </button>
                                                <button className={`${styles.button} ${styles.alt}`} onClick={this.goToLobby}>
                                                    Go To Lobby
                                                </button>

                                            </div>
                                            <div className={styles.skipBox}>
                                                <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                                <label htmlFor="checkbox">Skip Tutorial</label>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.showTutorial &&
                                        <Tutorial room={this.state.room} players={this.state.players} />
                                    }
                                </div>
                                <div className={`${styles.innerContainer} ${this.state.showPubSection == true && styles.show}`}>

                                    <div style={{ zIndex: 20, }}>
                                        {
                                            this.state.doConfetti &&
                                            <Confetti
                                                width={window.innerWidth}
                                                height={window.innerHeight}
                                                initialVelocityY={20}
                                                numberOfPieces={500}
                                                recycle={false}
                                                confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}
                                                initialVelocityY={{ min: -10, max: -30, }}
                                                initialVelocityX={{ min: -10, max: 10, }}
                                                onConfettiComplete={() => this.setState({ doConfetti: false, })}
                                            />
                                        }
                                    </div>

                                    <div className={`${styles.playerContainer} ${styles.left} ${this.state.showPlayers && styles.show} ${this.state.isRace && styles.hideTables}`}>
                                        <img src={tablesLeft} className={styles.tables} />
                                        <div className={styles.playerColumn}>
                                            {
                                                this.state.players.map((x, index) => {
                                                    if (index % 2 == 0) {
                                                        return <Player
                                                            className={styles.player}
                                                            key={index}
                                                            player={x}
                                                            left={true}
                                                            show={true}
                                                            isRace={this.state.isRace}
                                                            showCorrectAnswer={this.state.showCorrectAnswer}
                                                            showRaceAnswers={this.state.showRacePlayersValidation}
                                                            raceQuestion={this.state.raceQuestion}
                                                            raceAnswerIndexes={this.state.raceAnswerIndexes}
                                                            roundNumber={this.state.roundNumber}
                                                            questionNumber={this.state.questionNumber}
                                                        />
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.playerContainer} ${styles.right} ${this.state.showPlayers && styles.show} ${this.state.isRace && styles.hideTables}`}>
                                        <div className={styles.playerColumn}>
                                            {
                                                this.state.players.map((x, index) => {
                                                    if (index % 2 == 1) {
                                                        return <Player
                                                            className={styles.player}
                                                            key={index}
                                                            player={x}
                                                            show={true}
                                                            isRace={this.state.isRace}
                                                            showCorrectAnswer={this.state.showCorrectAnswer}
                                                            showRaceAnswers={this.state.showRacePlayersValidation}
                                                            raceQuestion={this.state.raceQuestion}
                                                            raceAnswerIndexes={this.state.raceAnswerIndexes}
                                                            roundNumber={this.state.roundNumber}
                                                            questionNumber={this.state.questionNumber}
                                                        />
                                                    }
                                                })
                                            }
                                        </div>
                                        <img src={tablesRight} className={`${styles.tables}`} />
                                    </div>
                                    <div className={styles.barSection}>
                                        <div className={`${styles.raceSegments} ${styles.left} ${this.state.isRace && styles.show}`}>
                                            {
                                                this.getRaceSegments(true)
                                            }
                                        </div>
                                        <div id='bar' className={styles.barContainer}>
                                            <div id="bar-anchor-left" className={`${styles.barAnchor} ${styles.left}`}></div>
                                            <img className={styles.barImg} src={barImg} />
                                            <div id="bar-anchor-right" className={`${styles.barAnchor} ${styles.right}`}></div>
                                        </div>
                                        <div className={`${styles.raceSegments} ${styles.right} ${this.state.isRace && styles.show}`}>
                                            {
                                                this.getRaceSegments(false)
                                            }
                                        </div>
                                    </div>
                                    <>
                                        <div className={`${styles.roundBar} ${this.state.showRoundsHeader && styles.show}`}>
                                            {
                                                this.state.rounds?.map((r, i) => {
                                                    return <div key={i} className={`${styles.roundIndicator} ${this.state.isRace == false && r.number == this.state.roundNumber ? styles.currentRound : ""}`}>
                                                        <p>{r.name}</p>
                                                    </div>
                                                })
                                            }
                                            <div className={`${styles.raceIndicator} ${this.state.isRace ? styles.currentRound : ""}`}>
                                                <p>Race!</p>
                                            </div>
                                        </div>
                                        <div className={`${styles.questionBar} ${this.state.showQuestionsHeader && styles.show}`}>
                                            {
                                                [...Array(this.state.questionsPerRound)].map((q, i) => {
                                                    return <div className={`${styles.questionBlock} ${i < this.state.questionNumber ? styles.questionChecked : ""} ${i + 1 == this.state.questionNumber && styles.current}`}>
                                                        <div className={`${styles.checkmark} ${i < this.state.questionNumber ? styles.questionChecked : ""}`}>{i + 1}</div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        {
                                            this.state.showRaceHeader &&
                                            <>
                                                <div className={styles.raceHeader}><strong className={styles.highlight}>{this.state.raceQuestionsLeft}</strong> {this.state.raceQuestionsLeft == 1 ? "question" : "questions"} until time at the bar!</div>
                                            </>
                                        }
                                    </>
                                    {
                                        this.state.showCategorySection &&
                                        <>
                                            <div className={`${styles.categorySection} ${this.state.showCategories && styles.show}`}>
                                                <div className={styles.categoryContainer}>
                                                    <div className={styles.categoryText}>{this.state.categorySelector.name}, please choose a category...</div>
                                                    <div className={styles.categorySelectorContainer}>
                                                        {
                                                            this.state.categories.map((c, i) => {
                                                                return <div key={i} className={`${styles.categoryOption} ${c.toString() === this.state.selectedCategory ? styles.selected : ""}`}>
                                                                    <p className={styles.categoryOptionText}>{c.toString()}</p>
                                                                </div>;
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.showGetReady &&
                                        <>
                                            <div className={`${styles.getReadySection} ${this.state.showGetReadyBox && styles.show}`}>
                                                <div className={styles.getReadyContainer}>
                                                    <div className={styles.getReadyPanel}>
                                                        <h1 className={styles.roundText}>{this.state.rounds.find(r => r.number == this.state.roundNumber).name}</h1>
                                                    </div>
                                                    <div className={styles.getReadyTextContainer}>
                                                        <h4 className={styles.getReadyText}>Get Ready For</h4>
                                                    </div>
                                                    <div className={styles.categoryTextContainer}>
                                                        <h4 className={styles.categoryText}>{this.state.selectedCategory}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.showQuestion &&
                                        <>
                                            <div className={styles.questionSection}>
                                                {
                                                    <div className={styles.questionAnswerContainer}>
                                                        <div className={`${styles.question} ${this.getQuestionFontSizeStyle()} ${this.state.showQuestionText && styles.show}`}>
                                                            <div className={styles.questionNumber}><p>{this.state.questionNumber}</p></div>
                                                            {this.state.timer > 0 &&
                                                                <Timer className={`${styles.timerBorder}`} pauseTimer={this.state.pauseTimer} seconds={this.state.timer} strokeWidth="25" strokeColour="#FFE400" />
                                                            }
                                                            {this.state.question.question}
                                                        </div>
                                                        {
                                                            this.state.question.answers.map((a, i) => {
                                                                return <div key={i} className={`${styles.answerOption} ${this.state.showAnswerOptions && styles.show} ${this.state.showCorrectAnswer && a.isCorrect ? styles.correctAnswer : ""}`}>
                                                                    <div className={`${styles.playerBox} ${styles.left}`}>
                                                                        {
                                                                            this.state.players.map((p, i) => {
                                                                                if (a.answer == p.pubData.answer) {
                                                                                    if (i % 2 == 0) {
                                                                                        return <div className={`${styles.player} ${this.state.showPlayerAnswers && styles.show}`}>
                                                                                            <Lottie
                                                                                                options={getAvatarById(p.avatar).idleAnim}
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                isClickToPauseDisabled={true}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className={`${styles.playerBox} ${styles.right}`}>
                                                                        {
                                                                            this.state.players.map((p, i) => {
                                                                                if (a.answer == p.pubData.answer) {
                                                                                    if (i % 2 == 1) {
                                                                                        return <div className={`${styles.player} ${this.state.showPlayerAnswers && styles.show} ${styles.flip}`}>
                                                                                            <Lottie
                                                                                                options={getAvatarById(p.avatar).idleAnim}
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                isClickToPauseDisabled={true}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <p className={styles.questionText}>{a.answer.toString()}</p>
                                                                </div>;
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.showRaceIntro &&
                                        <>
                                            <div className={`${styles.raceStartPopup} ${this.state.showRaceStartPopup && styles.show}`}>
                                                <div className={`${styles.textBox} ${this.state.showRacePopupText && styles.show}`}>{this.getRacePopupText()}</div>
                                                <div className={`${styles.playerRow} ${this.state.showRacePopupPlayers && styles.show}`}>
                                                    {
                                                        this.getRacePopupPlayerList()
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.showRaceQuestion &&
                                        <>
                                            <div className={`${styles.questionSection} ${styles.race}`}>
                                                <div className={styles.questionAnswerContainer}>
                                                    <div className={`${styles.question} ${this.getQuestionFontSizeStyle()} ${this.state.showRaceQuestionText && styles.show}`}>
                                                        {this.state.timer > 0 &&
                                                            <Timer className={`${styles.timerBorder}`} seconds={this.state.timer} strokeWidth="25" strokeColour="#FFE400" />
                                                        }
                                                        {this.state.raceQuestion.question}
                                                    </div>
                                                    {
                                                        this.state.raceQuestion.answers?.map((a, i) => {
                                                            if (this.state.raceAnswerIndexes.includes(i)) {
                                                                return <div key={i} className={`${styles.answerOption} ${this.state.showRaceAnswerOptions && styles.show} ${this.state.showRaceCorrect ? a.isCorrect ? styles.correct : "" : ""}`}>
                                                                    <p className={styles.questionText}>{a.answer.toString()}</p>
                                                                    <div className={`${styles.playerBox} ${styles.left}`}>
                                                                        {
                                                                            this.state.players.map((p, pIndex) => {
                                                                                let answerIndex = this.state.raceAnswerIndexes.indexOf(i);
                                                                                if (p.pubData.raceAnswers[answerIndex] == true) {
                                                                                    if (pIndex % 2 == 0) {
                                                                                        return <div className={`${styles.player} ${this.state.showRacePlayersValidation && styles.show}`}>
                                                                                            <Lottie
                                                                                                options={getAvatarById(p.avatar).idleAnim}
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                isClickToPauseDisabled={true}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className={`${styles.playerBox} ${styles.right}`}>
                                                                        {
                                                                            this.state.players.map((p, pIndex) => {
                                                                                let answerIndex = this.state.raceAnswerIndexes.indexOf(i);
                                                                                if (p.pubData.raceAnswers[answerIndex] == true) {
                                                                                    if (pIndex % 2 == 1) {
                                                                                        return <div className={`${styles.player} ${this.state.showRacePlayersValidation && styles.show}`}>
                                                                                            <Lottie
                                                                                                options={getAvatarById(p.avatar).idleAnim}
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                isClickToPauseDisabled={true}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        this.state.showGameOver &&
                                        <>
                                            <div className={`${styles.gameOverSection}`}>
                                                <div className={styles.gameOverTextContainer}>
                                                    {this.state.winnerName == null ? "" : <h4 className={styles.gameOverText}>Hurrah!</h4>}
                                                </div>
                                                <div className={`${styles.gameOverContainer} ${this.state.showGameOverPanel && styles.show}`}>
                                                    <div className={styles.gameOverPanel}>
                                                        {this.state.winnerName == null ? <h1 className={styles.loserText}>No Winners</h1> : <h1 className={`${styles.winnerText} ${this.getWinnerFontSize()}`}>{this.state.winnerName}</h1>}
                                                    </div>
                                                    <div className={styles.gameOverTextContainer}>
                                                        {this.state.winnerName == null ? <h4 className={styles.gameOverText}>Bar is closed!</h4> : <h4 className={styles.gameOverText}>We Have a Winner</h4>}
                                                    </div>
                                                    <div className={styles.potatoRow}>
                                                        {
                                                            this.state.winners.map((w, i) => {
                                                                return <div className={styles.winnerTato}>
                                                                    <Lottie
                                                                        options={getAvatarById(w.avatar).idleAnim}
                                                                        width="100%"
                                                                        height="100%"
                                                                        isClickToPauseDisabled={true}
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={`${styles.buttonsSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                                    <button className={`${styles.button}`} onClick={() => this.signalNewGame()}>
                                                        Play Again
                                                    </button>
                                                    <button className={`${styles.button}`} onClick={this.goToLobby}>
                                                        Return to lobby
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}